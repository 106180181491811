import React from 'react';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Icon from '../../styles/atoms/icons';
import data from './data/SidebarDemoCard';
import { Container, Header, List, ListItem } from './styles/SidebarCard.styled';

const SidebarDemoCard = () => (
  <Container>
    <Header>
      <p className="heading">Get a personalized Demo</p>
      <List>
        {data.map(({ title, icon }) => (
          <ListItem key={title} className="list-item">
            <Icon id={icon} isImage />
            <p>{title}</p>
          </ListItem>
        ))}
      </List>
    </Header>
    <CallToAction
      className="sidebar-demo-cta"
      link="/demo"
      variant="primary"
      size="lg"
      value="Request a Demo"
    />
  </Container>
);

export default SidebarDemoCard;
